$primary-green: #238c69;
$highlight-green: #5dbf92;
$dark-green: #1a6c54;

$highlight-blue: #0db5fd;

$white: #ffffff;
$black: #000000;
$text-gray: #1f1f1f;
$medium-gray: #808080;
$light-gray: #d8d8d8;
$error-red: #8b0000f0;
$highlight-red: #d27c7c;
