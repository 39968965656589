// variables
@import 'variables';

// bootstrap
@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/bootstrap-grid';

@import 'bootstrap-icons/font/bootstrap-icons.css';

// ngx-toastr
@import 'ngx-toastr/toastr-bs5-alert';

// Font
@import 'https://fonts.googleapis.com/css?family=Raleway';

body,
h1,
h2,
h3,
h4 {
  font-family: 'Raleway', sans-serif;
}

.heading {
  /* Heading/Raleway Regular */
  font-family: 'Raleway', sans-serif;
  font-size: 3rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25;
}

.title {
  font-family: Raleway, sans-serif;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25;
}

.price {
  /* Heading/Almarena/h4-reg */
  font-family: Almarena, sans-serif;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.1;
}

.price-sub {
  font-family: Almarena, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.1;
}

.text-semibold {
  font-family: Raleway, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5;
}

.navigation-semi-bold {
  /* Navigation/Raleway SemiBold */
  font-family: 'Raleway', sans-serif;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.7;
}

.navigation-tab {
  /* Navigation/Tab/Raleway */
  font-family: 'Raleway', sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
}

.text-large {
  font-family: 'Raleway', sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5;
}

.text-regular {
  /* Text/Raleway Regular */
  font-family: 'Raleway', sans-serif;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.9;
}

.text-small {
  font-family: 'Raleway', sans-serif;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
}

.text-small-bold {
  @extend .text-small;
  font-weight: bold;
}

.button-medium {
  display: flex;
  height: 32px;
  padding: 4px 15px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 2px;
  background: $primary-green;
  color: $white;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.04);
  white-space: nowrap;
}

.button-medium:hover {
  background: $highlight-green;
  color: $white;
}

.button-medium:active {
  background: $primary-green !important;
  color: $white !important;
}

.button-medium-danger {
  @extend .button-medium;
  background: $error-red;
}

.button-medium-danger:hover {
  background: $highlight-red;
  color: $white;
}

.button-medium-danger:active {
  background: $highlight-red !important;
  color: $white !important;
}

.button-medium-inverted {
  display: flex;
  height: 32px;
  padding: 4px 15px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 2px;
  background: $white;
  color: $primary-green;
  border: 1px solid $primary-green;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.04);
  white-space: nowrap;
}

.button-medium-inverted:hover {
  background: $highlight-green;
  color: $white;
  border-color: $highlight-green;
}

.button-medium-inverted:active {
  background: $white !important;
  color: $primary-green !important;
  border-color: $primary-green !important;
}

//.leaflet-bar button {
//  background: $primary-green !important;
//  //color: $primary-green !important;
//}

// Leaflet general
.leaflet-control {
  border: 1px solid $black !important;
  border-radius: 4px !important;
}

// Zoom buttons
.leaflet-bar a {
  color: $black !important;
  text-indent: 0;
  line-height: 26px !important;
}

.leaflet-control-zoom-in {
  border-top-left-radius: 3px !important;
  border-top-right-radius: 3px !important;
}

.leaflet-control-zoom-out {
  border-bottom-left-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
}

// Search bar

.leaflet-control-geocoder-icon {
  border-radius: 3px !important;
  background-image: url('assets/search.svg') !important;
}

.leaflet-control-geocoder-form input {
  width: 12rem !important;
}

.leaflet-control-geocoder-alternatives {
  width: 12rem !important;
}
