@use '@angular/material' as mat;

@include mat.core();

$my-primary: mat.m2-define-palette(mat.$m2-indigo-palette, 500);
$my-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

$my-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
    ),
    typography:
      mat.m2-define-typography-config(
        $font-family: 'Raleway',
      ),
    density: 0,
  )
);
@include mat.core-theme($my-theme);

:root {
  @include mat.all-component-themes($my-theme);
}
